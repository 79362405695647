@import '~/src/assets/styles/colors';
@import '~/src/assets/styles/vars';
@import '~/src/index.scss';

.home {
    &__highlight {
        width: 100%;
        padding: 4rem 0;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 100vw;
            height: 110%;
            margin-left: -50vw;
            background-color: $light-gray;
            z-index: -1;
        }

        &__title {
            font-size: $m-fs-h2;
            font-weight: 600;
            margin-bottom: 2.5rem;
        }

        &__card-box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            text-align: start;

            @include desktop {
                display: flex;
                flex-direction: row;
                align-items: first baseline;
            }
        }

        &__btn {
            padding: 1rem 0;
            margin-top: 1rem;

            span {
                border: 1px solid $black;
                gap: 0.5rem;
                transition: all 0.3s ease-in-out;
            }

            &:hover {
                span {
                    background-color: $dark-green;
                    border: 1px solid $dark-green;
                    color: $white;
                }
            }

            &:hover &__icon {
                @keyframes bounceLeft {
                    0%,
                    100% {
                        transform: translateX(0);
                    }
                    50% {
                        transform: translateX(5px);
                    }
                }
                animation: bounceLeft 0.5s ease infinite;
            }
        }
    }

    &__contact-banner {
        width: 100%;
        border-radius: 10px;
        background: $green-gradation;
        padding: 2.375rem 1rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 5.75rem;

        &:hover &__btn {
            span {
                background: $pink-gradation;
                color: $white;
            }
        }

        &__title {
            color: $white;
            font-weight: 600;
            font-size: $m-fs-h2;
        }

        &__text {
            color: $white;
            font-size: $m-fs-h4;
            margin: 1rem 0 1.375rem 0;
        }

        &__btn {
            padding: 1rem 0;

            span {
                background-color: $white;
                color: $dark-green;
                transition: all 0.2s ease-in;
            }

            &:hover {
                span {
                    background: $pink-gradation;
                    color: $white;
                }
            }
        }
    }
}
