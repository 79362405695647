@import '~/src/assets/styles/colors';
@import '~/src/assets/styles/vars';
@import '~/src/index.scss';

.experience-card__bottom-container {
    &__skill {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 0.625rem;

        &__title {
            font-weight: 600;
            margin: 0.5rem 0 1rem 0;
            color: $dark-green;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;

            &__icon {
                color: $pink;
            }
        }

        &__list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        &__li {
            list-style: none;
            border-radius: 50px;
            font-size: $m-fs-h4;
            font-weight: 300;
            padding: 0.3rem 1rem;
            text-align: center;
            margin-right: 0.5rem;
            margin-bottom: 0.5rem;
            background-color: $gray;
            color: $text-gray;

            &:hover {
                color: $bright-green;
            }
        }
    }

    &__explanation {
        padding-inline: 1.1rem;

        &__li {
            font-size: $m-fs-h4;
            font-weight: 300;
            color: $text-gray;
            padding-bottom: 1rem;

            span {
                font-size: $m-fs-h4;
                color: $text-gray;
                font-weight: 500;
            }

            span.explanation-link {
                font-size: $m-fs-h5;
                font-weight: 400;
                color: $bright-green;
                text-decoration: underline;

                &:hover {
                    color: $pink;
                }
            }
        }
    }
}
