@import "~/src/assets/styles/colors";
@import "~/src/assets/styles/vars";
@import "~/src/index.scss";

.skills__skills-container {
    &__img-box {
        min-height: 100px;
        border-radius: 5px;
        background-color: $light-gray;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease-in;

        &__img {
            width: 60px;
        }

        &__text {
            color: $text-gray;
            font-weight: 500;
            font-size: $m-fs-h3;
        }

        &:hover {
            transform: rotateY(360deg);
            background: linear-gradient(180deg, $peach 0%, $light-gray 100%);
        }
    }

    &__name {
        margin-top: 0.8rem;
        color: $text-gray;
        font-weight: 500;
        font-size: $m-fs-h4;
    }
}
