@import '~/src/assets/styles/colors';
@import '~/src/assets/styles/vars';

.experiences {
    margin-bottom: 5.75rem;

    &__title {
        margin-bottom: 2.5rem;
    }

    &__projects__title {
        margin-top: 4rem;
        margin-bottom: -2.5rem;
    }
}
