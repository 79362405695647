@import '~/src/assets/styles/colors';
@import '~/src/assets/styles/vars';
@import '~/src/index.scss';

.footer {
    display: flex;
    justify-content: center;

    &__container {
        width: 100%;
        max-width: 1024px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.5rem 0;
    }

    &__btn {
        padding: 0.5rem 0;
    }

    &__logo {
        width: 45px;
        height: 45px;
        transition: transform 0.5s ease-in-out;

        &:hover {
            transform: scale(1.15);
        }
    }

    &__nav-list,
    &__ext-link-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        list-style: none;

        &__btn {
            padding: 0.5rem 0;
            margin: 0.7rem;
            color: $black;

            @include tablet__desktop {
                margin: 1.5rem;
            }

            &:hover {
                color: $bright-green;
            }
        }

        &__btn-icons {
            padding: 0.5rem 0;
            margin: 1rem 1rem 1.5rem 1rem;

            &__icon {
                width: 25px;
                height: 25px;

                &:hover {
                    color: $bright-green;
                    animation: circularShake 0.6s ease-in-out forwards;

                    @keyframes circularShake {
                        0%,
                        100% {
                            transform: rotate(0deg);
                        }
                        25% {
                            transform: rotate(-10deg);
                        }
                        75% {
                            transform: rotate(10deg);
                        }
                    }
                }
            }
        }
    }

    &__rights {
        text-align: center;
    }
}
