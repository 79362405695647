@import '~/src/assets/styles/colors';
@import '~/src/assets/styles/vars';
@import '~/src/index.scss';

.experience-card {
    width: 100%;
    margin-top: 5.5rem;

    &__jobTitle {
        font-size: $m-fs-h2;
        font-weight: 600;
        margin-bottom: 1.313rem;
    }

    &__top-container {
        border-radius: 20px 20px 0px 0px;
        background: linear-gradient(
            180deg,
            rgba(35, 201, 141, 0.11) 0%,
            rgba(35, 201, 141, 0) 100%
        );
        padding: 1rem;

        @include tablet__desktop {
            display: flex;
            flex-direction: row;
        }

        &:hover &__img {
            transform: scale(1.3);
        }

        &__img-box {
            width: 100%;
            max-width: 100%;
            height: 219px;
            background-color: $white;
            border-radius: 15px 15px 0 0;
            margin-right: 2rem;
            padding: 0.3rem;
            transition: max-width 0.5s ease-in-out, max-height 0.5s ease-in-out;
            overflow: hidden;

            @include tablet__desktop {
                min-width: 350px;
                max-width: 350px;
                border-radius: 15px 0 0 0;
            }
        }

        &__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px 10px 0 0;
            transition: transform 0.8s ease;

            @include tablet__desktop {
                border-radius: 10px 0 0 0;
            }
        }

        &__title {
            font-size: $m-fs-h2;
            font-weight: 700;
            color: $bright-green;
            margin-top: 1rem;
        }

        &__subtitle {
            font-weight: 500;
            margin: 1rem 0 0.5rem 0;
        }

        &__description {
            font-size: $m-fs-h4;
            font-weight: 300;
            color: $text-gray;
            line-height: 1.7;

            span {
                font-size: $m-fs-h4;
                color: $text-gray;
                font-weight: 500;
            }
        }

        &__link-box {
            list-style: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            margin: 1.5rem 0 1rem 0;

            @include tablet__desktop {
                justify-content: start;
            }
        }
    }

    &__bottom-container {
        border-radius: 0px 0px 20px 20px;
        background: linear-gradient(
            180deg,
            rgba(245, 245, 244, 0) 0%,
            #f5f5f4 100%
        );
        padding: 1rem;
    }
}
