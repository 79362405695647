@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

// 👉 Font family
$font-primary: 'Poppins', sans-serif;

// 👉 Font size
// Mobile: default
$m-fs-h1: 1.75rem; // 28px
$m-fs-h2: 1.25rem; // 20px
$m-fs-h3: 1rem; // 16px
$m-fs-h4: 0.875rem; // 14px
$m-fs-h5: 0.75rem; // 12px
$m-fs-body: 1rem; // 16px

// Tablet
$t-fs-h1: 1.75rem; // 28px
$t-fs-h2: 1.25rem; // 20px
$t-fs-h3: 1rem; // 16px
$t-fs-h4: 0.875rem; // 14px
$t-fs-body: 1rem; // 14px

// Desktop
$d-fs-h1: 2rem; // 32px
$d-fs-h2: 1.5rem; // 24px
$d-fs-h3: 1.25rem; // 20px
$d-fs-h4: 1.125rem; // 18px
$d-fs-body: 1rem; // 16px
