@import '~/src/assets/styles/colors';
@import '~/src/assets/styles/vars';

.skills {
    margin-bottom: 3rem;

    &__subtitle {
        margin: 2.625rem 0 1.438rem 0;
        font-size: $m-fs-h2;
    }

    &__skills-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        gap: 2.3rem;
        text-align: center;
    }
}
