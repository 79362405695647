@import '~/src/assets/styles/colors';
@import '~/src/assets/styles/vars';

.contact-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 0.75rem 0;

    &__icon {
        width: 25px;
        height: 25px;
    }

    &__description {
        font-size: 1rem;
        text-decoration: underline;
    }

    &:hover {
        color: $bright-green;
    }
}
