@import '~/src/assets/styles/colors';
@import '~/src/assets/styles/vars';
@import '~/src/index.scss';

.home__highlight__card {
    width: 100%;
    padding: 1.2rem;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 1.5;
    background: linear-gradient(
        180deg,
        #ededed 0%,
        rgba(237, 237, 237, 0) 100%
    );
    transition: all 0.8s ease-in-out;

    @include desktop {
        height: 510px;
    }

    &:hover {
        background: linear-gradient(
            180deg,
            rgba(35, 201, 141, 0.11) 0%,
            rgba(35, 201, 141, 0) 100%
        );
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    &:hover &__img-box__img {
        transform: scale(1.3);
    }

    &__link {
        @include s-tablet__tablet {
            display: flex;
            flex-direction: row;
            margin-bottom: 1rem;
        }
    }

    &__img-box {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 160px;
        background-color: $white;
        border-radius: 15px 15px 0 0;
        margin-right: 2rem;
        padding: 0.3rem;
        transition: max-width 0.5s ease-in-out, max-height 0.5s ease-in-out;
        overflow: hidden;

        @include s-tablet__tablet {
            max-width: 300px;
            max-height: 200px;
            border-radius: 15px 0 0 0;
        }

        &__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px 10px 0 0;
            transition: transform 0.8s ease;

            @include s-tablet__tablet {
                border-radius: 15px 0 0 0;
            }
        }
    }

    &__desc {
        @include s-tablet__tablet {
            margin-left: 2rem;
        }
    }

    &__skillTab-box {
        display: flex;
        gap: 0.5rem;
        margin-top: 2em;

        @include s-tablet__tablet {
            margin-top: 0;
        }
    }

    &__skillTab {
        font-size: $m-fs-h5;
        color: $light-gray;
        background-color: $dark-green;
        padding: 0.15rem 0.5rem;
        border-radius: 7px;
        font-weight: 400;
    }

    &__title {
        font-size: $m-fs-h2;
        font-weight: 600;
        margin: 0.7rem 0 0.5rem 0;
        color: $bright-green;

        &__link {
            text-decoration: none;
        }
    }

    &__description {
        font-size: $m-fs-h4;
        margin: 1.125rem 0 3rem 0;
        color: $text-gray;

        @include desktop {
            margin: 1.125rem 0 0 0;
        }

        @include s-tablet__tablet {
            margin: 1.125rem 0 0 0;
        }
    }

    &__icons {
        display: flex;
        justify-content: center;

        &__container {
            list-style: none;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 1.2rem;
            max-width: 220px;
        }

        &__icon-box {
            width: 54px;
            height: 54px;
            background-color: $gray;
            border-radius: 50%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            line-height: 1;

            &:hover {
                background-color: $white;
            }
        }

        &__icon-box:hover &__icon {
            transform: scale(1.1);
            color: $pink;
            background-color: $white;
        }

        &__icon {
            width: 25px;
            height: 25px;
            background-color: $gray;
            padding-bottom: none;
            transition: transform 0.5s ease-in-out;
        }
    }
}
