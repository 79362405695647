$dark-green: #066756;
$bright-green: #23c98d;
$peach: #fce8d1;
$pink: #ffb2b2;
$dark-pink: #ff6565;
$white: #ffffff;
$light-gray: #f5f5f4;
$gray: #ececec;
$dark-gray: #7f7f7f;
$text-gray: #606060;
$black: #000000;

$pink-gradation: linear-gradient(100deg, $dark-pink -3.49%, $pink 87.94%);
$green-gradation: linear-gradient(180deg, $bright-green 0%, $dark-green 100%);

$error: $dark-pink;
$disabled: $text-gray;
$disabled-bg: $gray;
