@import '~/src/assets/styles/colors';
@import '~/src/assets/styles/vars';
@import '~/src/index.scss';

.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;

    @include tablet__desktop {
        flex-direction: row;
    }

    &__my-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include tablet__desktop {
            max-width: 320px;
            height: 100%;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    &__img-container {
        // width: 100%;
        max-width: 200px;
        // height: 100%;
        max-height: 330px;

        &__img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    &__links-container {
        list-style: none;
        display: flex;
        flex-direction: column;
        margin: 3.375rem 0;
    }
}
