@import "~/src/assets/styles/colors";
@import "~/src/assets/styles/vars";

.header {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 9999;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba($white, 0.8);
    backdrop-filter: blur(2px);

    &__container {
        width: calc(100% - 2rem);
        max-width: 1024px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        background-color: transparent;
    }

    &__btn {
        padding: 0.5rem 0;
    }

    &__logo {
        width: 45px;
        height: 45px;
        transition: transform 0.5s ease-in-out;
        background-color: transparent;
        animation: rotate-circle 5s ease-in-out infinite;
        transform-origin: center;

        @keyframes rotate-circle {
            0%,
            100% {
                transform: rotate(0deg);
            }
            80% {
                transform: rotate(360deg);
            }
            30% {
                transform: rotate(0deg);
            }
        }

        &:hover {
            animation-play-state: paused;
        }
    }

    &__nav {
        background-color: transparent;
    }

    &__nav-btn {
        background-color: $gray;
        gap: 0.5rem;
        padding: 0.5rem 1rem 0.5rem 1.5rem;
        transition: 0.5s ease-in-out;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

        &__text {
            font-weight: 500;
            color: $text-gray;
            font-size: $m-fs-body;
            background-color: transparent;
        }

        &__arrow {
            width: 22px;
            height: 22px;
            color: $bright-green;
            transition: 0.5s ease-in-out;
            background-color: transparent;
        }

        &:hover &__arrow {
            @keyframes bounce {
                0%,
                100% {
                    transform: translateY(0);
                }
                50% {
                    transform: translateY(5px);
                }
            }
            animation: bounce 0.5s ease infinite;
        }

        &__arrow--up {
            @keyframes bounceAndRotate {
                0%,
                100% {
                    transform: translateY(0) rotate(180deg);
                }
                50% {
                    transform: translateY(3px) rotate(180deg);
                }
            }
            animation: bounceAndRotate 0.5s ease infinite;
        }
    }

    &__nav-overlay {
        position: absolute;
        top: 5rem;
        right: 1rem;
        background-color: $gray;
        border-radius: 20px;
        list-style: none;
        padding: 1rem;
        width: 40%;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

        &__btn {
            width: 100%;
            text-align: start;
            padding: 0.5rem 0;
            margin: 0.4rem 0;
            color: $text-gray;
            font-weight: 500;
            position: relative;

            &:hover {
                color: $bright-green;

                &::after {
                    content: "";
                    display: inline-block;
                    width: 5px;
                    height: 5px;
                    background-color: $bright-green;
                    border-radius: 50%;
                    margin: 0 0 5px 5px;
                    animation: bounce 0.5s ease infinite;
                }
            }
        }
    }

    &__nav-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        background-color: transparent;

        &__btn {
            position: relative;
            display: inline-block;
            padding: 0.5rem 0;
            margin: 0 1.25rem;
            text-align: center;
            transition: color 0.3s;
            overflow: hidden;

            &:hover {
                color: $bright-green;
            }

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 3px;
                background-color: $bright-green;
                bottom: 0;
                left: 0;
                transform: scaleX(0);
                transform-origin: bottom right;
                transition: transform 0.3s ease-out;
            }

            &:hover::before {
                transform: scaleX(1);
                transform-origin: bottom left;
            }
        }

        &__btn-highlight {
            padding: 0.5rem 0;
            margin-left: 1.25rem;

            span {
                font-weight: 500;
                color: $white;
                background: $pink-gradation;
            }

            &:hover {
                @keyframes shake {
                    0%,
                    100% {
                        transform: translateX(0);
                    }
                    50% {
                        transform: translateX(3px);
                    }
                }
                animation: shake 0.5s ease infinite;
            }
        }
    }

    &__selected {
        color: $bright-green;
    }
}
