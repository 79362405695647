@import '~/src/assets/styles/colors';
@import '~/src/assets/styles/vars';
@import '~/src/index.scss';

.page-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 100px 0;

    &__icon {
        font-size: 50px;
        margin-bottom: 30px;
        color: $error;
    }

    h1 {
        color: $error;
    }

    &__btn {
        margin-top: 30px;
        padding: 1rem 0;

        span {
            max-width: 150px;
            color: $dark-pink;
            background: $peach;
        }

        &:hover {
            animation: shake 0.5s ease infinite;
        }
    }
}
