@import '~/src/assets/styles/colors';
@import '~/src/assets/styles/vars';

* {
    box-sizing: border-box;
    font-family: $font-primary;
    font-size: $m-fs-body;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

body {
    background-color: rgb(252, 252, 252);
}

button {
    background-color: transparent;
    border: transparent;
    cursor: pointer;
    line-height: 1;
}

.btn {
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1.5rem;
    font-weight: 600;
}

.section-title {
    font-size: $m-fs-h1;
    font-weight: 600;
    background: $green-gradation;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

a,
a:visited {
    color: inherit;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

// 👉 Responsive Size Setting
@mixin mobile {
    @media screen and (max-width: 480px) {
        @content;
    }
}

@mixin s-tablet {
    @media screen and (min-width: 480px) and (max-width: 767px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: 767px) and (max-width: 1024px) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: 1024px) {
        @content;
    }
}

@mixin s-tablet__tablet {
    @media screen and (min-width: 480px) and (max-width: 1024px) {
        @content;
    }
}

@mixin tablet__desktop {
    @media screen and (min-width: 767px) {
        @content;
    }
}

@mixin mobile-center {
    @include mobile {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @content;
    }
}
