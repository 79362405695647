@import '~/src/assets/styles/colors';
@import '~/src/assets/styles/vars';

.text-input {
    width: 100%;
    position: relative;

    &--include-error {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__label {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        padding-bottom: 0.313rem;
        color: $bright-green;
    }

    &__field {
        width: 100%;
        border-radius: 3px;
        background-color: $white;
        outline: none;

        color: $dark-green;
        border: 1px solid $dark-gray;
        font-size: 1rem;
        padding: 0.688rem 0.938rem;

        &:focus {
            border: 1px solid $bright-green;
            caret-color: $bright-green;
            background-color: rgba($peach, 0.3);

            &::placeholder {
                opacity: 0;
            }
        }

        &--textarea {
            min-height: 150px;
        }

        &--disabled {
            color: $disabled;
            background-color: $disabled-bg;
            border: 1px solid $dark-gray;

            &::placeholder {
                color: $disabled;
            }
        }

        &--error {
            border: 1px solid $error;
            background-color: rgba($error, 0.1);
            color: $error;

            &::placeholder {
                color: $error;
            }
        }

        &--no-icon {
            padding-left: 0.938em;
        }
    }

    &__error-text {
        color: $error;
        line-height: normal;
        margin-left: 1rem;
    }
}
