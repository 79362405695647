@import '~/src/assets/styles/colors';
@import '~/src/assets/styles/vars';
@import '~/src/index.scss';

.home {
    &__about-me {
        padding-bottom: 3rem;

        @include tablet__desktop {
            display: flex;
            flex-direction: row;
        }

        &__title {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            padding-bottom: 2rem;

            &__text {
                margin-right: 1rem;
            }

            &__icon {
                font-size: $m-fs-h1;
                transform-origin: 50% 70%; // Rotate around the bottom center
                animation: wave 4s ease-in-out infinite;

                @keyframes wave {
                    0%,
                    20%,
                    40%,
                    50%,
                    100% {
                        transform: rotate(0deg);
                    }
                    10% {
                        transform: rotate(15deg);
                    }
                    30% {
                        transform: rotate(15deg);
                    }
                    45% {
                        transform: rotate(15deg);
                    }
                }
            }
        }

        &__p {
            line-height: 180%;
            color: $text-gray;

            h2 {
                color: $black;
                font-weight: 600;
                padding-bottom: 0.5rem;

                span {
                    font-weight: 400;
                }
            }

            span {
                color: $dark-green;
                font-weight: 600;
            }
        }

        &__img-container {
            display: flex;
            align-items: center;
            margin-left: 2rem;
        }

        &__img {
            @include tablet__desktop {
                max-width: 200px;
            }
        }
    }
}
